<template>
    <div class="widgets_list">
        <!-- <h3>Recent Messages</h3> -->
        <ul class="tab_sec">
            <li @click="msg_type = 'sms'" :class="{ active: msg_type === 'sms' }">SMS</li>
            <li @click="msg_type = 'chat'" :class="{ active: msg_type === 'chat' }">Portal Chat</li>
        </ul>
        <template v-if="msg_type === 'sms'">
            <ul class="message_list" v-if="latestSMS.length">
                <li v-for="(sms, s) of latestSMS" :key="s">
                    <div class="user_box">
                        <img :src="sms.photo ? sms.photo : require('@/assets/images/male-female.png')">
                        <div class="user_info">
                            <h4>{{ sms.name }}<span>{{ moment.utc(sms.created_at).local().format('ll | LT') }}</span></h4>
                            <p>
                                {{ sms.sms.length > 60 ? sms.sms.substring(0, 60) + "..." : sms.sms  }}
                                <span @click="rowOption($event)" class="option_btn"><i class="fas fa-ellipsis-v"></i></span>
                                <span class="row_option">
                                    <ul>
                                        <li>Reply</li>
                                        <li>Mark as read</li>
                                        <li>See more</li>
                                        <li>Archive</li>
                                    </ul>
                                </span>
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
            <p v-else class="blank_text">You haven't received any messages yet!</p>
        </template>
        <template v-if="msg_type === 'chat'">
            <ul class="message_list">
                <li v-for="(chat, c) of latestPortalChat" :key="c">
                    <div class="user_box">
                        <img v-if="chat.send_by == 0" :src="chat.employer ? chat.employer.profile_pic : require('@/assets/images/male-female.png')">
                        <img v-else :src="chat.contact ? chat.contact.photo : require('@/assets/images/male-female.png')">
                        <div class="user_info">
                            <h4 v-if="chat.send_by == 0">{{ chat.employer ? chat.employer.full_name : 'Unknown' }}<span>{{ moment.utc(chat.created_at).local().format('ll | LT') }}</span></h4>
                            <h4 v-else>{{ chat.contact ? chat.contact.name : 'Unknown' }}<span>{{ moment.utc(chat.created_at).local().format('ll | LT') }}</span></h4>
                            <p>{{chat.message}}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </template>
        <router-link class="see_all" :to="{ name: 'SmsCenterIndex' }">Message Center</router-link>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import moment from 'moment'

    export default {
        name: 'Stats Widget',

        data () {
            return {
                msg_type: 'sms',
                tab: 'Messages',
                moment
            };
        },

        computed: mapState({
            latestSMS: state => state.smsCenterModule.latestSMS,
            latestPortalChat: state => state.portalChatModule.latestPortalChat,
        }),

        mounted () {
            let elmt = document.querySelectorAll('.user_info p');

            for (let i = 0; i < elmt.length; i++) {
                elmt[i].addEventListener('click', function() {
                    let elmtAll = document.querySelectorAll('.row_option');
                    for (let i = 0; i < elmtAll.length; i++) {
                        if (elmtAll[i].classList.contains('active')) {
                            elmtAll[i].classList.remove('active');
                        }
                    }
                })
            }

            const vm = this;

            vm.getLatestSMS();
            vm.latestchat();
        },

        methods: {
            ...mapActions({
                getLatestSMS: 'smsCenterModule/getLatestSMS',
                latestchat   : 'portalChatModule/latestChat',
            }),

            rowOption (e) {
                e.stopPropagation();
                let el = e.currentTarget.nextElementSibling;
                let allEl = document.querySelectorAll('.row_option');

                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                } else {
                    for (let i = 0; i < allEl.length; i++) {
                        allEl[i].classList.remove('active');
                    }

                    el.classList.add('active');
                }
            }
        },
    }
</script>

<style scoped>
.tab_sec{
    padding: 15px 20px 0 20px;
    margin-bottom: 0;
}
.tab_sec li {
    padding-bottom: 15px;
}
.message_list{
    max-height: 460px;
    overflow-y: scroll;
}
.message_list::-webkit-scrollbar{
    width: 4px;
}
.message_list::-webkit-scrollbar-thumb{
    background: #dbdbdb;
    border-radius: 2px;
}
.message_list .user_info h4{
    font-size: 13px;
    line-height: 18px;
}
.message_list .user_box img{
    width: 40px;
    height: 40px;
}
.message_list .user_info h4 span {
    font-size: 11px;
    line-height: 14px;
    color: #5a5a5a;
    padding: 3px 0;
}
</style>

